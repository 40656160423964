:root {
  --white: #ffffff;
  --black: #000000;

  --blue: #1355d7;
  --blue-100: #e2f6ff;
  --blue-200: #007bff;
  --blue-300: #0093d4;
  --blue-400: #409cff;
  --blue-500: #004186;

  --gray-100: #444444;
  --gray-200: #555;
  --gray-300: #f1f1f1;
  --gray-400: #cccccc;
  --gray-500: #dddddd;
  --gray-600: #eee;
  --gray-700: #aaa;
  --gray-800: #f5f5f5;

  --green: #00b53d;
}

body,
input,
textarea,
select {
  font-family: "Rubik", sans-serif;
}

body {
  font-size: 1rem;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
  padding-top: 90px;
}

strong {
  font-weight: 500 !important;
}

a {
  color: var(--gray-100);
  transition: all 0.3s ease;
}

a:hover {
  color: var(--black);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
.font-h1,
.font-h2,
.font-h3,
.font-h4,
.font-h5,
.font-h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.font {
  &-weight {
    &-light {
      font-weight: 300 !important;
    }
    &-normal {
      font-weight: 400 !important;
    }
    &-medium {
      font-weight: 500 !important;
    }
  }

  &-h1 {
    font-size: 3rem;
  }
  &-h2 {
    font-size: 2rem;
  }
  &-h3 {
    font-size: 1.75rem;
  }
  &-h4 {
    font-size: 1.5rem;
  }
  &-h5 {
    font-size: 1.25rem;
  }
  &-h6 {
    font-size: 0.938rem;
  }
  &-h7 {
    font-size: 0.9rem;
  }

  &-display {
    font-size: 3.5rem;
  }
}

@media (min-width: 576px) {
  .font-sm {
    &-h1 {
      font-size: 3rem;
    }
    &-h2 {
      font-size: 2rem;
    }
    &-h3 {
      font-size: 1.75rem;
    }
    &-h4 {
      font-size: 1.5rem;
    }
    &-h5 {
      font-size: 1.25rem;
    }
    &-h6 {
      font-size: 0.938rem;
    }
    &-h7 {
      font-size: 0.9rem;
    }
  }
}

@media (min-width: 768px) {
  .font-md {
    &-h1 {
      font-size: 3rem;
    }
    &-h2 {
      font-size: 2rem;
    }
    &-h3 {
      font-size: 1.75rem;
    }
    &-h4 {
      font-size: 1.5rem;
    }
    &-h5 {
      font-size: 1.25rem;
    }
    &-h6 {
      font-size: 0.938rem;
    }
    &-h7 {
      font-size: 0.9rem;
    }
  }
}

@media (min-width: 992px) {
  .font-lg {
    &-h1 {
      font-size: 3rem;
    }
    &-h2 {
      font-size: 2rem;
    }
    &-h3 {
      font-size: 1.75rem;
    }
    &-h4 {
      font-size: 1.5rem;
    }
    &-h5 {
      font-size: 1.25rem;
    }
    &-h6 {
      font-size: 0.938rem;
    }
    &-h7 {
      font-size: 0.9rem;
    }
  }
}

@media (min-width: 1200px) {
  .font-xl {
    &-h1 {
      font-size: 3rem;
    }
    &-h2 {
      font-size: 2rem;
    }
    &-h3 {
      font-size: 1.75rem;
    }
    &-h4 {
      font-size: 1.5rem;
    }
    &-h5 {
      font-size: 1.25rem;
    }
    &-h6 {
      font-size: 0.938rem;
    }
    &-h7 {
      font-size: 0.9rem;
    }
  }
}

.container {
  max-width: 1280px;
}

.box-info {
  background: rgba(0, 147, 213, 0.9);
  padding: 40px;
  color: var(--white);
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  font-weight: 300;

  @media (min-width: 992px) {
    width: 380px;
    height: 280px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

.box-thumb {
  left: 50px;
}

.box-right {
  left: auto;
  right: 0;
}

.box-right.box-thumb {
  left: auto;
  right: 50px;
}

.btn {
  min-width: 240px;
  height: 45px;
  border-radius: 25px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  padding-right: 1.75rem;
  padding-left: 1.75rem;

  &-primary-outline {
    color: var(--blue-200);
    border-color: var(--blue-200);
    background-color: transparent;

    &:hover {
      background-color: var(--blue-200);
      color: var(--white);
    }
  }

  &-secondary-outline {
    color: var(--white);
    border-color: var(--white);
    background-color: transparent;

    &:hover {
      background-color: var(--white);
      color: var(--blue-200);
    }
  }

  &-auto {
    min-width: inherit;
  }

  &-outline-success {
    &:hover {
      color: var(--white);

      color: #28a745;
      border-color: #28a745;
      background-color: transparent;
    }
  }
}

.about {
  height: 480px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  background-attachment: fixed;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    height: 70%;
    background-color: rgba(255, 255, 255, 0.9);
    display: block;
    width: 100%;
    transform: translateY(-50%);

    @media (min-width: 992px) {
      height: 490px;
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    background-size: 100%;
    height: 600px;
  }
}

.bg {
  &-gray {
    background-color: var(--gray-300);
  }

  &-blue-100 {
    background-color: var(--blue-100);
  }

  &-blue-500 {
    background-color: var(--blue-500);
  }
}

.heading {
  &::before {
    content: "";
    height: 4px;
    width: 70px;
    background-color: var(--blue);
    display: block;
    margin-bottom: 1rem;
  }
}

.heading-white {
  &::before {
    background-color: var(--blue-400);
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 101;
  box-shadow: 0 0 8px var(--gray-700);
  height: 90px;
  display: flex;
  align-items: center;
  transition: height 0.5s ease;

  img {
    transition: height 0.5s ease;
  }

  &.active {
    img {
      height: 50px;
    }
  }
}

.testimonials {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--blue-300);
    display: block;
    width: 30%;
  }
}

blockquote {
  position: relative;
  font-style: italic;
  font-size: 1.125rem;

  @media (min-width: 992px) {
    padding-left: 3rem;
  }

  &::before {
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    left: -3rem;
    top: 1.5rem;
  }
}

.none:hover {
  text-decoration: none;
}

.small {
  font-size: 90%;
}

.text {
  &-blue {
    color: var(--blue);
  }

  &-green {
    color: var(--green);
  }

  &-gray-100 {
    color: var(--gray-100);
  }

  &-gray-200 {
    color: var(--gray-200);
  }

  &-gray-400 {
    color: var(--gray-400);
  }
}

a.text-gray-400:hover {
  color: var(--white);
}

a.text-gray-100:hover {
  color: var(--black);
}

.social-circle {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--gray-400);
  background-color: var(--white);
}

.box-image {
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex !important;
  align-items: center;
  position: relative;
  color: var(--white);

  @media (min-width: 992px) {
    background-size: 100%;
    min-height: 480px;
    height: 600px;
    background-attachment: fixed;
  }
}

.banner {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;

  @media (min-width: 992px) {
    position: relative;
    top: 60px;
    height: 390px;
  }

  &::before {
    background-color: rgba(2, 69, 148, 0.9);
    position: absolute;
    left: 0;
    top: 50%;
    height: 70%;
    width: 100%;
    content: "";
    transform: translateY(-50%);

    @media (min-width: 992px) {
      top: 0;
      transform: none;
      width: 50%;
      height: 100%;
    }
  }
}

@media (min-width: 992px) {
  .banner.banner-alt {
    height: 290px;
  }
}

.box-image.box-image-alt {
  height: 50vh;
}

@media (min-width: 992px) {
  .box-image.box-image-alt {
    min-height: inherit;
    height: 300px;
  }
}

.pt {
  &-px-1 {
    padding-top: 1px;
  }

  &-px-2 {
    padding-top: 2px;
  }

  &-px-3 {
    padding-top: 3px;
  }

  &-px-4 {
    padding-top: 4px;
  }
}

@media (min-width: 992px) {
  footer {
    border-top: 1px solid var(--gray-500);
  }
}

.text-underline {
  text-decoration: underline;
}

li {
  line-height: 1.6;

  &::marker {
    color: var(--blue-500);
  }
}

img {
  max-width: 100%;
  display: block;

  @media (min-width: 992px) {
    max-width: initial;
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .collapse-title {
    border-top: 1px solid var(--gray-600);
    padding: 13px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .collapse-title::after {
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-weight: 900;
    font-size: 1.25rem;
  }
}

@media (max-width: 991px) {
  .collapse-title.active::after {
    content: "\f106";
  }
}

@media (max-width: 991px) {
  .collapse-content {
    background-color: var(--gray-800);
    padding: 25px;
    border-top: 1px solid var(--gray-600);
    transition: height 0.5s ease;
    overflow: hidden;

    &:not(.active) {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .collapse-content {
    display: block !important;
  }
}

.w {
  &-20 {
    width: 20%;
  }

  &-25 {
    width: 25%;
  }

  &-50 {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .w-sm {
    &-20 {
      width: 20% !important;
    }

    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }
  }
}

@media (min-width: 768px) {
  .w-md {
    &-20 {
      width: 20% !important;
    }

    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }
  }
}

@media (min-width: 992px) {
  .w-lg {
    &-20 {
      width: 20% !important;
    }

    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }
  }
}

.consulting-clients {
  position: relative;
}

@media (min-width: 992px) {
  .consulting-clients-item:nth-child(even) {
    background-color: var(--gray-600);
  }

  .consulting-clients-item:hover {
    background-color: var(--blue-100);
  }
}

@media (max-width: 991px) {
  .consulting-clients {
    &::before {
      background-color: var(--white);
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
    }

    &-item {
      border-bottom: 1px solid var(--gray-500);
      border-left: 1px solid var(--gray-500);
    }

    &-last {
      border-left: none;
    }
  }
}

@media (max-width: 991px) {
  .box-testimonial {
    background-color: var(--white);
    padding: 40px 35px 20px;
  }
}

@media (min-width: 992px) {
  .responsive {
    &-item {
      overflow: hidden;

      a {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 1px;
          display: block;
          background-color: var(--black);
          transition: width 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }

        &.active {
          &::before {
            width: 100%;
          }
        }
      }

      &:hover {
        a::before {
          width: 100%;
        }
      }

      &:last-child a::before {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .responsive {
    background-color: var(--white);
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    transition: height 0.5s ease;
    overflow: hidden;

    &:not(.active) {
      display: none;
    }

    &-item {
      border-top: 1px solid var(--gray-500);

      &:not(.last) a {
        display: block;
        padding: 15px 20px;
      }

      &.last {
        padding: 15px 20px;
      }

      &.last a {
        display: flex;
      }
    }
  }
}

.menu span {
  display: none;
}

.menu .active {
  display: block;
}

@media (max-width: 991px) {
  header {
    height: 90px;

    img {
      height: 50px;
    }

    &.active img {
      height: 40px;
    }
  }
}

.slider-item {
  width: 100%;
}

.tns-nav {
  display: flex;
  justify-content: center;
  padding-top: 25px;

  button {
    margin-right: 10px;
    background-color: var(--gray-200);
    height: 5px;
    width: 40px;
    text-indent: -99999rem;
    cursor: pointer;
    border: none;
    outline: none;

    &.tns-nav-active {
      background-color: var(--blue);
    }
  }
}

.text-none {
  text-decoration: none !important;

  &::before {
    content: none !important;
  }
}

@media (max-width: 991px) {
  .menu-contact {
    .icon {
      margin-right: 10px;
    }

    &-form,
    &-whatsapp {
      width: 100%;
      margin-right: 7px;
    }

    &-form {
      margin-right: 0;
      margin-left: 7px;
    }
  }
}

@media (max-width: 575px) {
  .menu-contact {
    &-whatsapp {
      margin-bottom: 15px;
    }

    &-form,
    &-whatsapp {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media (min-width: 992px) {
  .menu-contact {
    .btn {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      overflow: hidden;

      &:hover {
        box-shadow: none !important;
      }
    }

    .name,
    .icon {
      transition: transform 0.4s ease-in-out;
    }

    &-whatsapp {
      .name {
        transform: translateX(100%);
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    &-form {
      .icon {
        transform: translateX(100%);
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    &:hover {
      .menu-contact-whatsapp {
        .name {
          transform: translateX(0);
          opacity: 1;
          width: auto;
          height: auto;
          margin-left: 10px;
        }
      }

      .menu-contact-form {
        position: relative;
        z-index: 1;

        .name {
          transform: translateX(100%);
          opacity: 0;
          width: 0;
          height: 0;
        }

        .icon {
          transform: translateX(0);
          opacity: 1;
          width: auto;
          height: auto;
        }
      }
    }
  }
}

.seePolicies {
  color: var(--gray-400);
  transition: all 0.3s ease;
  cursor: pointer;
}

.seePolicies:hover {
  color: var(--white);
  text-decoration: underline;
}

.smodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.6);
  z-index: 103;
  display: none;
}

.smodal .smodal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 25px;
}

.smodal .smodal-close {
  width: 30px;
  height: 30px;
  background-color: var(--blue-500);
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px #999;
  cursor: pointer;
}

.smodal .smodal-content {
  position: relative;
  margin: 0 auto;
  padding: 40px 2%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px #999;
  font-size: 1.15rem;
  width: 900px;
  max-width: 900px;
  height: 95%;
  max-height: 95%;
}

.smodal .pdf-content {
  position: absolute;
  overflow: scroll;
  overflow-x:auto;
  width: 90%;
  max-width: 90%;
  height: 90%;
  max-height: 90%;
}
